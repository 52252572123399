/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.nowrap {
  white-space: nowrap !important;
}

table.nowrap td,
td.nowrap,
th.nowrap {
  white-space: nowrap !important;
}

table.hide-overflow td,
td.hide-overflow,
th.hide-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.a11y-hidden {
  position: absolute !important;
  left: -10000px !important;
  top: auto !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
}

.invisible {
  visibility: hidden;
}

.note {
  color: #656565;
  font-size: 12px;
}

.nudged-up {
  margin-top: -1px;
}

.nudged-down {
  margin-top: 1px;
}

.null-spacer-top {
  margin-top: 0 !important;
}

.null-spacer-bottom {
  margin-bottom: 0 !important;
}

.spacer {
  margin: 8px !important;
}

.spacer-left {
  margin-left: 8px !important;
}

.spacer-right {
  margin-right: 8px !important;
}

.spacer-bottom {
  margin-bottom: 8px !important;
}

.spacer-top {
  margin-top: 8px !important;
}

.big-spacer {
  margin: 16px !important;
}

.big-spacer-left {
  margin-left: 16px !important;
}

.big-spacer-right {
  margin-right: 16px !important;
}

.big-spacer-bottom {
  margin-bottom: 16px !important;
}

.big-spacer-top {
  margin-top: 16px !important;
}

.huge-spacer {
  margin: 40px !important;
}

.huge-spacer-bottom {
  margin-bottom: 40px !important;
}

.huge-spacer-top {
  margin-top: 40px !important;
}

.huge-spacer-left {
  margin-left: 40px !important;
}

.huge-spacer-right {
  margin-right: 40px !important;
}

.little-spacer {
  margin: 4px !important;
}

.little-spacer-left {
  margin-left: 4px !important;
}

.little-spacer-right {
  margin-right: 4px !important;
}

.little-spacer-bottom {
  margin-bottom: 4px !important;
}

.little-spacer-top {
  margin-top: 4px !important;
}

.padded {
  padding: 8px !important;
}

.little-padded {
  padding: 4px !important;
}

.big-padded {
  padding: 16px !important;
}

.padded-top {
  padding-top: 8px !important;
}

.padded-right {
  padding-right: 8px !important;
}

.padded-bottom {
  padding-bottom: 8px !important;
}

.padded-left {
  padding-left: 8px !important;
}

.little-padded-top {
  padding-top: 4px !important;
}

.little-padded-right {
  padding-right: 4px !important;
}

.little-padded-bottom {
  padding-bottom: 4px !important;
}

.little-padded-left {
  padding-left: 4px !important;
}

.big-padded-top {
  padding-top: 16px;
}
.big-padded-bottom {
  padding-bottom: 16px;
}

.big-padded-right {
  padding-right: 16px;
}

.big-padded-left {
  padding-left: 16px;
}

.huge-padded-top {
  padding-top: 40px;
}

.huge-padded-bottom {
  padding-bottom: 40px;
}

td.little-spacer-left {
  padding-left: 4px !important;
}

td.little-spacer-right {
  padding-right: 4px !important;
}

td.spacer-left {
  padding-left: 8px !important;
}

td.spacer-right {
  padding-right: 8px !important;
}

td.spacer-bottom {
  padding-bottom: 8px !important;
}

td.spacer-top {
  padding-top: 8px !important;
}

td.big-spacer-left,
th.big-spacer-left {
  padding-left: 16px !important;
}

td.big-spacer-right {
  padding-right: 16px !important;
}

td.big-spacer-bottom {
  padding-bottom: 16px !important;
}

td.big-spacer-top {
  padding-top: 16px !important;
}

td.huge-spacer-right,
th.huge-spacer-right {
  padding-right: 40px !important;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.borderless {
  border: none !important;
}

.bordered {
  border: 1px solid #e6e6e6;
}

.bordered-left {
  border-left: 1px solid #e6e6e6;
}

.bordered-right {
  border-right: 1px solid #e6e6e6;
}

.bordered-bottom {
  border-bottom: 1px solid #e6e6e6;
}

.bordered-top {
  border-top: 1px solid #e6e6e6;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.max-width-100 {
  max-width: 100% !important;
}

.max-width-80 {
  max-width: 80% !important;
}

.max-width-60 {
  max-width: 60% !important;
}

.width-100 {
  width: 100% !important;
}

.width-80 {
  width: 80% !important;
}

.width-60 {
  width: 60% !important;
}

.width-55 {
  width: 55% !important;
}

.width-50 {
  width: 50% !important;
}

.width-40 {
  width: 40% !important;
}

.width-30 {
  width: 30% !important;
}

.width-25 {
  width: 25% !important;
}

.width-20 {
  width: 20% !important;
}

.width-15 {
  width: 15% !important;
}

.width-10 {
  width: 10% !important;
}

.abs-width-100 {
  width: 100px !important;
}

.abs-width-150 {
  width: 150px !important;
}

.abs-width-240 {
  width: 240px !important;
}

.abs-width-300 {
  width: 300px !important;
}

.abs-width-400 {
  width: 400px !important;
}

.abs-width-600 {
  width: 600px !important;
}

.abs-width-800 {
  width: 800px !important;
}

.abs-height-50 {
  height: 50px !important;
}

.abs-height-100 {
  height: 100% !important;
}

.max-height-100 {
  max-height: 100% !important;
}

.justify {
  margin-bottom: -1em;
  text-align: justify;
}

.justify > .ib {
  display: inline-block;
}

.justify:after {
  display: inline-block;
  width: 100%;
  content: ' ';
}

.first-letter-uppercase::first-letter {
  text-transform: uppercase;
}

.disabled-pointer-events {
  pointer-events: none !important;
}

.display-block {
  display: block !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-flex-row {
  display: flex !important;
  flex-direction: row;
}

.display-flex-column {
  display: flex !important;
  flex-direction: column;
}

.display-flex-center {
  display: flex !important;
  align-items: center;
}

.display-flex-justify-start {
  display: flex !important;
  justify-content: flex-start !important;
}

.display-flex-justify-center {
  display: flex !important;
  justify-content: center;
}

.display-flex-justify-end {
  display: flex !important;
  justify-content: flex-end;
}

.display-flex-space-around {
  display: flex !important;
  justify-content: space-around;
}

.display-flex-space-between {
  display: flex !important;
  justify-content: space-between;
}

.display-flex-stretch {
  display: flex !important;
  align-items: stretch;
}

.display-flex-start {
  display: flex !important;
  align-items: flex-start !important;
}

.display-flex-end {
  display: flex !important;
  align-items: flex-end;
}

.display-flex-wrap {
  display: flex !important;
  flex-wrap: wrap;
}

.display-inline-flex-baseline {
  display: inline-flex !important;
  align-items: baseline;
}

.display-inline-flex-start {
  display: inline-flex !important;
  align-items: flex-start;
}

.display-inline-flex-center {
  display: inline-flex !important;
  align-items: center;
}

.position-absolute {
  position: absolute !important;
}

.position-relative {
  position: relative !important;
}

.rounded {
  border-radius: 2px;
}

.flex-1 {
  flex: 1;
}

.flex-1-0-auto {
  flex: 1 0 auto;
}

.flex-0 {
  flex: 0 0 auto;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
  min-width: 0;
}

.space-between {
  justify-content: space-between !important;
}

.new-loading {
  opacity: 0.5;
  transition: opacity 0.5s ease;
}

.slash-separator {
  margin-left: 5px;
  margin-right: 5px;
}

.slash-separator:after {
  content: '/';
  color: rgba(68, 68, 68, 0.3);
}

.horizontal-pipe-separator {
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.horizontal-pipe-separator > .horizontal-separator {
  margin: 0 4px;
}

.horizontal-separator {
  min-width: 16px;
  height: 1px;
  flex-grow: 1;
  background-color: #e6e6e6;
}

.vertical-separator {
  width: 1px;
  min-height: 16px;
  flex-grow: 1;
  background-color: #e6e6e6;
}

.vertical-pipe-separator {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  margin-right: 60px;
}

.vertical-pipe-separator > .vertical-separator {
  margin: 4px auto;
}

.capitalize {
  text-transform: capitalize !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.no-outline,
.no-outline:focus {
  outline: none !important;
}

.bg-danger {
  background-color: #D02F3A;
  color: #fff;
}

.bg-warning {
  background-color: #fcf8e3;
  color: #6f4f17;
}

.bg-info {
  background-color: #4b9fd5;
  color: #fff;
}

.bg-success {
  background-color: #00aa00;
  color: #fff;
}

.bg-muted {
  background-color: #f3f3f3;
  color: inherit;
}

.muted {
  color: #666666;
}

.leak-box {
  background-color: #fbf3d5;
  border: 1px solid #f1e8cb;
  padding: 4px 6px;
}

.break-word {
  word-break: break-word;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}
