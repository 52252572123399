/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.navbar-global,
.navbar-global .navbar-inner {
  background-color: #262626;
  z-index: 421;
}

.navbar-global .navbar-limited {
  display: flex;
}

.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  margin-left: -8px;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 4px solid transparent;
}

.navbar-login {
  margin-right: -10px;
}

.navbar-avatar {
  margin-right: -8px;
  padding: 8px !important;
  border: none !important;
}

.navbar-icon {
  display: inline-block;
  height: 48px;
  padding: 16px 12px !important;
  border-bottom: none !important;
  color: #fff !important;
}

.navbar-plus {
  margin-right: -8px;
  position: relative;
  z-index: 3;
}

.global-navbar-menu {
  display: flex;
  align-items: center;
  height: 48px;
}

.global-navbar-menu > li > a,
.global-navbar-menu .navbar-login {
  display: block;
  height: 48px;
  padding: 8px 10px;
  line-height: 32px;
  border-bottom: 4px solid transparent;
  box-sizing: border-box;
  color: #ccc;
  font-size: 13px;
  letter-spacing: 0.05em;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus,
.global-navbar-menu > li > a.active,
.global-navbar-menu > li > a:hover,
.global-navbar-menu > li > a:focus,
.navbar-login.active,
.navbar-login:hover,
.navbar-login:focus {
  background-color: #020202;
  border-bottom-color: #4b9fd5;
}

.global-navbar-menu-right {
  flex: 1;
  justify-content: flex-end;
  margin-left: 40px;
}

.global-navbar-menu-right .navbar-search {
  flex: 0 1 240px; /* Workaround for SONAR-10971 */
  min-width: 0;
}

.global-navbar-menu-right .navbar-search .search-box,
.global-navbar-menu-right .navbar-search .search-box-input {
  min-width: 0;
  width: 100%;
}

@media print {
  .navbar-global {
    display: none !important;
  }
}
