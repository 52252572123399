/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.location-index {
  position: relative;
  display: inline-block;
  vertical-align: top;
  line-height: 16px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 2px;
  background-color: #d18582;
  color: #fff;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  transition: background-color 0.3s ease;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.selected > .location-index,
.location-index.selected {
  background-color: #a4030f;
}

.location-index.muted {
  background-color: #cdcdcd;
}

.location-index.is-leading:first-child {
  margin-left: 0;
}

.location-index.is-leading:not(:first-child) {
  margin-left: -22px;
}

.location-index[tabindex] {
  cursor: pointer;
}

.location-index[tabindex]:hover,
a:hover > .location-index {
  background-color: #a4030f;
}

.location-index[tabindex]:focus {
  outline: none;
}

.source-line-code-inner .location-index {
  line-height: 16px;
  margin: 1px;
  margin-left: 4px;
  margin-right: 4px;
}

.source-line-code-inner .location-index + .location-index {
  margin-left: 0;
}
