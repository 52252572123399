/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.radio-card {
  display: flex;
  flex-direction: column;
  width: 450px;
  min-height: 210px;
  background-color: #fff;
  border: solid 1px #e6e6e6;
  border-radius: 3px;
  box-sizing: border-box;
  margin-right: 16px;
  transition: all 0.2s ease;
}

.radio-card.animated {
  height: 0;
  border-width: 0;
  overflow: hidden;
}

.radio-card.animated.open {
  height: 210px;
  border-width: 1px;
}

.radio-card.highlight {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.radio-card:last-child {
  margin-right: 0;
}

.radio-card:focus {
  outline: none;
}

.radio-card-vertical {
  width: 100%;
  min-height: auto;
}

.radio-card-actionable {
  cursor: pointer;
}

.radio-card-actionable:not(.disabled):hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  transform: translateY(-2px);
}

.radio-card-actionable.selected {
  border-color: #236a97;
}

/*
 * Disabled transform property because it moves the element to a new stacking context
 * creating z-index conflicts with other components.
 * This is a problem with a vertical list of RadioCards where a select might be above another RadioCard
 */
.radio-card-actionable.radio-card-vertical:not(.disabled):hover {
  box-shadow: none;
  transform: none;
}

.radio-card-actionable.radio-card-vertical:not(.selected):not(.disabled):hover {
  border-color: #cae3f2;
}

.radio-card-actionable.selected .radio-card-recommended {
  border: solid 1px #236a97;
  border-top: none;
}

.radio-card-actionable.disabled {
  cursor: not-allowed;
  background-color: #ebebeb;
  border-color: #ddd;
}

.radio-card-actionable.disabled h2,
.radio-card-actionable.disabled ul {
  color: #bbb;
}

.radio-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0;
}

.radio-card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px 16px;
}

.radio-card-body .alert {
  margin-bottom: 0;
}

.radio-card-recommended {
  position: relative;
  padding: 6px 16px;
  left: -1px;
  bottom: -1px;
  width: 450px;
  color: #fff;
  background-color: #4b9fd5;
  border-radius: 0 0 3px 3px;
  box-sizing: border-box;
  font-size: 12px;
}
