/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.list-group {
  margin-bottom: 20px;
  padding-left: 0;
}

.list-group-item,
button.list-group-item {
  position: relative;
  z-index: 2;
  display: block;
  margin-bottom: -1px;
  padding: 5px 10px;
  border: 1px solid transparent;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}

.list-group-item.depth-1 {
  padding-left: 31px;
}

.list-group-item.depth-2 {
  padding-left: 51px;
}

.list-group-item.depth-3 {
  padding-left: 71px;
}

.list-group-item:last-child {
  margin-bottom: 0;
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  z-index: 3;
  border-color: #4b9fd5 !important;
  background-color: #cae3f2;
}

.list-group-item:hover {
  z-index: 3;
  border-color: #4b9fd5 !important;
}

.list-group-item + .list-group-item {
  border-top-color: #e6e6e6;
}

a.list-group-item {
  color: #333;
  transition: none;
}

.list-group-item-heading {
  margin-top: 5px;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.list-group-item-heading:after {
  content: '';
  display: table;
  clear: both;
}
