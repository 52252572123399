/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.project-card-main {
  flex: 1 1 auto;
  overflow: hidden;
}

.project-card-meta {
  flex: 0 0 170px;
  overflow: hidden;
  background-color: rgba(230, 230, 230, 0.25);
  height: 100%;
  box-sizing: border-box;
}

.project-card-meta .tags-list span {
  display: inline;
}

.project-card-measure-value-line {
  height: 24px;
}

@media (max-width: 1320px) {
  .project-card-measure-secondary-info {
    display: none;
  }
}

.project-card-leak {
  background-color: #fbf3d5;
}

.project-card-disabled *:not(g):not(path) {
  color: #bbb;
}

.project-card-disabled .rating,
.project-card-disabled .size-rating,
.project-card-disabled .duplications-rating:after,
.project-card-disabled .level {
  background-color: #ebebeb;
}

.project-card-disabled .duplications-rating {
  border-color: #ebebeb;
}

.project-card-disabled .project-card-main *:not(.favorite-link) svg path,
.project-card-disabled .project-card-meta path {
  fill: #ebebeb !important;
}
