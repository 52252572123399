/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.project-info-list > li {
  /* 1px to not cut icons on the left */
  padding-left: 1px;
  padding-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.project-info-tags {
  position: relative;
}

.project-info-deleted-profile,
.project-info-deprecated-rules {
  margin: 4px -6px 4px;
  padding: 3px 6px !important;
  border: 1px solid #f4b1b0;
  border-radius: 3px;
  background-color: #f2dede;
}

.project-info-deleted-profile a,
.project-info-deprecated-rules a {
  color: #0E516F;
  border-color: darken(#cae3f2);
}
