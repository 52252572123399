/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
html,
body {
  color: #333;
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.23076923;
}

h1,
.h1 {
  line-height: 24px;
  color: #333;
  font-size: 16px;
  font-weight: 400;
}

h1 img,
.h1 img,
h1 svg,
.h1 svg {
  vertical-align: middle;
  transform: translateY(-1px);
}

h2,
.h2 {
  line-height: 24px;
  color: #333;
  font-size: 15px;
  font-weight: 400;
}

h2 img,
.h2 img,
h2 svg,
.h2 svg {
  vertical-align: middle;
  transform: translateY(-1px);
}

h3,
.h3 {
  line-height: 24px;
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

h3 img,
.h3 img,
h3 svg,
.h3 svg {
  vertical-align: middle;
  transform: translateY(-1px);
}

h4,
.h4 {
  line-height: 24px;
  color: #333;
  font-size: 13px;
  font-weight: 600;
}

h4 img,
.h4 img,
h4 svg,
.h4 svg {
  vertical-align: middle;
  transform: translateY(-1px);
}

h5,
.h5 {
  line-height: 24px;
  color: #333;
  font-size: 13px;
  font-weight: 600;
}

h5 img,
.h5 img,
h5 svg,
.h5 svg {
  vertical-align: middle;
  transform: translateY(-1px);
}

h6,
.h6 {
  line-height: 24px;
  color: #333;
  font-size: 13px;
  font-weight: 600;
}

h6 img,
.h6 img,
h6 svg,
.h6 svg {
  vertical-align: middle;
  transform: translateY(-1px);
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

em {
  font-style: italic;
}

strong {
  font-weight: 600;
}

.underline {
  text-decoration: underline;
}

mark {
  background: none;
  color: #333;
  font-weight: bold;
}

blockquote {
  border-left: 3px solid #e6e6e6;
  padding: 0 8px;
  line-height: 1.5;
}

blockquote cite {
  line-height: 1.5;
  color: #656565;
  font-size: 12px;
}

small,
.small {
  font-size: 12px !important;
}

.medium {
  font-size: 14px !important;
}

.big {
  font-size: 16px !important;
}

.huge {
  font-size: 24px !important;
}

.gigantic {
  font-size: 36px !important;
}

.zero-font-size {
  font-size: 0 !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-top {
  vertical-align: top !important;
}

.text-middle {
  vertical-align: middle !important;
}

.text-bottom {
  vertical-align: bottom !important;
}

.text-text-top {
  vertical-align: text-top !important;
}

.text-text-bottom {
  vertical-align: text-bottom !important;
}

.text-baseline {
  vertical-align: baseline !important;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-limited-small {
  display: inline-block;
  max-width: 8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-limited {
  display: inline-block;
  max-width: 16vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-no-transform {
  text-transform: none;
}

.text-light {
  font-weight: 300 !important;
}

.text-normal {
  font-weight: normal !important;
}

.text-bold {
  font-weight: bold !important;
}

.text-muted {
  color: #656565;
}

.text-muted-2 {
  color: #b4b4b4;
}

.text-danger {
  color: #D02F3A !important;
}

.text-warning {
  color: #ed7d20 !important;
}

.text-info {
  color: #4b9fd5 !important;
}

.text-success {
  color: #00aa00 !important;
}

.monospaced {
  line-height: 18px;
  font-family: Consolas, 'Ubuntu Mono', 'Liberation Mono', Menlo, Courier, monospace;
  font-size: 12px;
}
