/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.global-loading {
  width: 300px;
  margin: 200px auto 0;
  white-space: nowrap;
}

.global-loading .global-loading-spinner {
  vertical-align: middle;
  width: 80px;
  height: 80px;
}

.global-loading-text {
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
  font-size: 36px;
  font-weight: 300;
}
