/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
/* for example java annotations */
.code .a {
  color: #808000;
}

/* constants */
.code .c {
  color: #660e80;
  font-style: normal;
  font-weight: bold;
}

/* javadoc */
.code .j {
  color: #666666;
  font-style: normal;
}

/* classic comment */
.code .cd {
  color: #666666;
  font-style: italic;
}

/* C++ doc */
.code .cppd {
  color: #666666;
  font-style: italic;
}

/* keyword */
.code .k {
  color: #0071ba;
  font-weight: 600;
}

/* string */
.code .s {
  color: #277b31;
  font-weight: normal;
}

/* keyword light*/
.code .h {
  color: #000080;
  font-weight: normal;
}

/* preprocessing directive */
.code .p {
  color: #347235;
  font-weight: normal;
}

.sym {
  cursor: hand;
  cursor: pointer;
}

.highlighted {
  background-color: #cae3f2;
  animation: highlightedFadeIn 0.3s forwards;
}

@keyframes highlightedFadeIn {
  from {
    background-color: transparent;
  }

  to {
    background-color: #cae3f2;
  }
}
