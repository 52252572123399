/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.code-components .page-actions {
  margin-top: -35px;
}

.code-components .boxed-group.search-results {
  padding-top: 16px;
}

.code-components .boxed-group.search-results .page-actions {
  margin-top: -50px;
}

.code-components .table-wrapper {
  margin: 0 20px;
}

.code-components table.data {
  table-layout: fixed;
}

.code-components table.data td {
  padding: 8px 6px;
  vertical-align: middle;
}

.code-components table.data th {
  padding-top: 24px;
}

.code-components table.data th,
.code-components table.data td:not(.thin) {
  width: 84px;
}

.code-components table.data td.code-name-cell,
.code-components table.data th.code-name-cell {
  width: auto;
}

.code-components table.data th.thin,
.code-components table.data td.thin {
  width: 10px !important;
}

.code-components table.data tr.current-folder {
  border-bottom: 1px solid #e6e6e6;
}

.code-components table.data tr.current-folder td {
  padding-bottom: 16px !important;
  padding-top: 10px !important;
}

.code-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
}

.code-breadcrumbs > li {
  padding: 5px 5px 3px;
  display: flex;
}

.code-breadcrumbs > li:first-child {
  padding-left: 0;
}

.code-breadcrumbs > li::after {
  position: relative;
  top: 1px;
  padding-left: 10px;
  color: #656565;
  font-size: 11px;
  content: '>';
}

.code-breadcrumbs > li:last-child::after {
  display: none;
}

@media (max-width: 1200px) {
  .code-name-cell .badge {
    display: none;
  }
}

.code-search {
  margin-bottom: 10px;
  display: flex;
}

.code-components-header {
  position: sticky;
  top: 105px;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1;
}

table > thead > tr.code-components-header > th {
  vertical-align: middle;
}

.code-child-component-icon {
  display: inline-block;
  border-left: 1px solid #656565;
  border-bottom: 1px solid #656565;
  margin-left: 8px;
  margin-bottom: 8px;
  margin-right: 4px;
  height: 8px;
  width: 4px;
}

.code-components .no-file .h1 {
  position: fixed;
  top: 50%;
}
