/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.duplications-rating {
  position: relative;
  display: inline-flex;
  vertical-align: top;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 3px solid #ed7d20;
  border-radius: 24px;
  box-sizing: border-box;
}

.duplications-rating-small {
  width: 24px;
  height: 24px;
  border-width: 3px;
}

.duplications-rating-big {
  width: 40px;
  height: 40px;
  border-width: 3px;
}

.duplications-rating-huge {
  width: 60px;
  height: 60px;
  border-width: 4px;
  border-radius: 30px;
}

.duplications-rating-muted {
  border-color: #b4b4b4 !important;
}

.duplications-rating-muted:after {
  background-color: #b4b4b4 !important;
}

.duplications-rating:after {
  border-radius: 24px;
  content: '';
}

.duplications-rating-A {
  border-color: #008223;
}

.duplications-rating-A:after {
  display: none;
}

.duplications-rating-B {
  border-color: #C6E056;
}

.duplications-rating-B:after {
  width: 6px;
  height: 6px;
  background-color: #C6E056;
}

.duplications-rating-small.duplications-rating-B:after {
  width: 2px;
  height: 2px;
}

.duplications-rating-big.duplications-rating-B:after {
  width: 12px;
  height: 12px;
}

.duplications-rating-huge.duplications-rating-B:after {
  width: 18px;
  height: 18px;
}

.duplications-rating-C {
  border-color: #F4D348;
}

.duplications-rating-C:after {
  width: 8px;
  height: 8px;
  background-color: #F4D348;
}

.duplications-rating-small.duplications-rating-C:after {
  width: 6px;
  height: 6px;
}

.duplications-rating-big.duplications-rating-C:after {
  width: 16px;
  height: 16px;
}

.duplications-rating-huge.duplications-rating-C:after {
  width: 24px;
  height: 24px;
}

.duplications-rating-D {
  border-color: #F69D53;
}

.duplications-rating-D:after {
  width: 12px;
  height: 12px;
  background-color: #F69D53;
}

.duplications-rating-small.duplications-rating-D:after {
  width: 8px;
  height: 8px;
}

.duplications-rating-big.duplications-rating-D:after {
  width: 24px;
  height: 24px;
}

.duplications-rating-huge.duplications-rating-D:after {
  width: 36px;
  height: 36px;
}

.duplications-rating-E {
  border-color: #D02F3A;
}

.duplications-rating-E:after {
  width: 14px;
  height: 14px;
  background-color: #D02F3A;
}

.duplications-rating-small.duplications-rating-E:after {
  width: 10px;
  height: 10px;
}

.duplications-rating-big.duplications-rating-E:after {
  width: 28px;
  height: 28px;
}

.duplications-rating-huge.duplications-rating-E:after {
  width: 42px;
  height: 42px;
}
